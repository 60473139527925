import type { Options } from "@hey-api/client-fetch";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import type {
  PutV1LinksByProfileLinkData,
  PutV1LinksByProfileLinkError,
  PutV1LinksByProfileLinkResponse,
} from "../../client";
import { putV1LinksByProfileLinkMutation } from "../../client/@tanstack/react-query.gen";
import { getLinkOptions } from "./get-link";

const mutationKey = ["updateLink"];

const getLinkQueryKey = (profileLink: string) => getLinkOptions(profileLink).queryKey;

export const useUpdateLink = () => {
  const queryClient = useQueryClient();
  return useMutation<
    PutV1LinksByProfileLinkResponse,
    PutV1LinksByProfileLinkError,
    Options<PutV1LinksByProfileLinkData>
  >({
    ...putV1LinksByProfileLinkMutation(),
    mutationKey,
    onSettled: async (_, __, { path: { profileLink } }) => {
      await queryClient.invalidateQueries({ queryKey: getLinkQueryKey(profileLink) });
    },
  });
};
