import * as VisuallyHidden from "@radix-ui/react-visually-hidden";
import { type PropsWithChildren, Suspense } from "react";
import { useParams } from "react-router-dom";
import { useSnapshot } from "valtio";
import {
  Drawer,
  DrawerContent,
  DrawerDescription,
  DrawerTitle,
} from "../../../../../components/drawer";
import { LoadingSpinner } from "../../../../../components/loading";
import { useGetClient, useGetLead, useGetProfile } from "../../../../../services";
import { homeState } from "../../../../../state/home";
import { LinkCreatorDrawerContent } from "./LinkCreatorDrawerContent";
import { LinkCreatorHeader } from "./LinkCreatorHeader";

export const LinkCreatorDrawer = ({ children }: PropsWithChildren) => {
  const { organizationId } = useParams();
  if (!organizationId) throw new Error("Organization ID is required");

  const { linkCreatorLeadId } = useSnapshot(homeState);

  const { data: lead } = useGetLead(
    {
      leadID: linkCreatorLeadId ?? "",
      organizationID: organizationId,
    },
    { enabled: !!linkCreatorLeadId }
  );

  const { data: profile } = useGetProfile(
    {
      organizationId,
      profileId: lead?.profileId ?? "",
    },
    {
      enabled: !!lead?.profileId,
    }
  );

  const { data: client } = useGetClient(
    {
      organizationID: organizationId,
      clientId: lead?.clientId ?? "",
    },
    {
      enabled: !!lead?.clientId,
    }
  );

  const handleLinkCreatorClose = () => {
    // TODO: Save link
    homeState.linkCreatorLeadId = null;
  };

  return (
    <Drawer
      direction="right"
      open={!!linkCreatorLeadId}
      onClose={handleLinkCreatorClose}
      shouldScaleBackground={false}
    >
      {children}

      <DrawerContent className="min-w-[80%] max-w-[80%]" direction="right">
        <VisuallyHidden.Root>
          <DrawerTitle>Edit link</DrawerTitle>
          <DrawerDescription>Edit the link for the profile version</DrawerDescription>
        </VisuallyHidden.Root>
        {lead && (
          <>
            <LinkCreatorHeader
              profile={profile}
              client={client}
              handleClose={handleLinkCreatorClose}
            />
            {/* <div className="flex-grow overflow-auto">
              <LinkEditor
                key={profile?.profileId}
                profileId={profile?.profileId!}
                managerProfileId={localStorage.getItem("current-profile-id")!}
                profileLinkId={lead?.profileLinkId}
              />
            </div> */}

            <Suspense fallback={<Loading />}>
              <LinkCreatorDrawerContent lead={lead} organizationId={organizationId} />
            </Suspense>
          </>
        )}
      </DrawerContent>
    </Drawer>
  );
};

const Loading = () => (
  <div className="flex justify-center items-center h-full w-full">
    <LoadingSpinner size="small" />
  </div>
);
