import { memo } from "react";
import type { AccountProfile, Lead, ProfileLinkConfig } from "../../../../../client";
import { Avatar, Checkbox, Switch } from "../../../../../components";
import { useGetLink, useGetProfile, useUpdateLink } from "../../../../../services";
import { getImageUrl } from "../../../../../utils/image";

interface Props {
  lead: Lead;
  organizationId: string;
}

export const LinkCreatorDrawerContent = memo(({ lead, organizationId }: Props) => {
  const { data: profile } = useGetProfile(
    {
      organizationId,
      profileId: lead.profileId ?? "",
    },
    {
      enabled: !!lead.profileId,
    }
  );

  const { data: link } = useGetLink(lead.profileLinkId ?? "", {
    enabled: !!lead.profileLinkId,
  });

  const { mutate: updateLink, isPending, variables } = useUpdateLink();

  const handleUpdateLink = (update: Partial<ProfileLinkConfig>) => {
    if (!lead.profileLinkId) return;
    if (!link?.link_config_data) return;

    updateLink({
      path: { profileLink: lead.profileLinkId },
      body: {
        ...link.link_config_data,
        ...update,
      },
    });
  };

  const disabled = isPending;

  const { link_config_data }: AccountProfile = {
    ...link,
    link_config_data: {
      ...link?.link_config_data,
      ...variables?.body,
    },
  };

  return (
    <div className="flex flex-col xl:flex-row gap-8 p-4 sm:px-10 sm:py-12 overflow-y-auto">
      <div className="flex flex-col grow gap-4">
        <div className="flex flex-row gap-4 items-center">
          <Checkbox
            checked={link_config_data?.profileLinkPersonalInfoConfig?.showPersonalInfo}
            disabled={disabled}
            onCheckedChange={(checked) => {
              if (typeof checked !== "boolean") return;

              handleUpdateLink({
                profileLinkPersonalInfoConfig: {
                  ...link_config_data?.profileLinkPersonalInfoConfig,
                  showPersonalInfo: checked,
                },
              });
            }}
          />
          <Avatar
            alt={
              typeof profile?.profileData.name === "string" ? profile.profileData.name : "Profile"
            }
            imageUrl={profile?.profilePhotoId ? getImageUrl(profile.profilePhotoId) : undefined}
            className="w-20 h-20"
          />

          <div className="flex flex-col">
            <p className="text-sm font-semibold">
              {typeof profile?.profileData.name === "string" ? profile.profileData.name : "Profile"}
            </p>
            {typeof profile?.profileData.title === "string" && (
              <p className="text-sm">{profile.profileData.title}</p>
            )}
          </div>
        </div>
      </div>

      <div className="bg-gray-1 border border-gray-3 border-dashed rounded-3xl grow shrink-0">
        <div className="p-4 flex flex-col gap-4">
          <div className="flex flex-row gap-4 items-center justify-end">
            <p className="text-xs">VOCS styling active</p>
            <Switch disabled={disabled} />
          </div>
        </div>
      </div>
    </div>
  );
});
